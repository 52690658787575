<script>
import LayoutLoose from "@/router/layouts/LayoutLoose";
import appConfig from "@/app.config";

import {mapState} from "vuex";
import {HalfCircleSpinner} from "epic-spinners";

export default {
  page: {
    title: "Ban Manager",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    LayoutLoose,
    BanlistSkeleton: () => import('@/components/lazy/banmanager/view/BanlistSkeleton')
  },
  data() {
    return {
      banlist_id: this.$route.params.banlist_id
    }
  }
};
</script>

<template>
  <LayoutLoose>
    <BanlistSkeleton :banlist_id="banlist_id" />
  </LayoutLoose>
</template>